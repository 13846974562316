import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignQueryVariables = Types.Exact<{
  stub: Types.Scalars['String']['input'];
}>;


export type CampaignQuery = { __typename?: 'Query', campaign: Array<{ __typename?: 'campaign', id: number, title: string, stub: string, platform?: Types.Platform_Enum | null }> };

export type CampaignClientQueryVariables = Types.Exact<{
  hubspot_company_id: Types.Scalars['bigint']['input'];
}>;


export type CampaignClientQuery = { __typename?: 'Query', clients_by_pk?: { __typename?: 'clients', hubspot_company_id: string, name?: string | null, username?: string | null, hubspot_deals: Array<{ __typename?: 'hubspot_deal', id: number, project: { __typename?: 'project', id: number, campaigns: Array<{ __typename?: 'campaign', id: number, stub: string }> } }> } | null };


export const CampaignDocument = gql`
    query campaign($stub: String!) {
  campaign(
    where: {hide_on_client_system: {_eq: false}, stub: {_eq: $stub}}
    limit: 1
  ) {
    id
    title
    stub
    platform
  }
}
    `;

/**
 * __useCampaignQuery__
 *
 * To run a query within a React component, call `useCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignQuery({
 *   variables: {
 *      stub: // value for 'stub'
 *   },
 * });
 */
export function useCampaignQuery(baseOptions: Apollo.QueryHookOptions<CampaignQuery, CampaignQueryVariables> & ({ variables: CampaignQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignQuery, CampaignQueryVariables>(CampaignDocument, options);
      }
export function useCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignQuery, CampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignQuery, CampaignQueryVariables>(CampaignDocument, options);
        }
export function useCampaignSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CampaignQuery, CampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CampaignQuery, CampaignQueryVariables>(CampaignDocument, options);
        }
export type CampaignQueryHookResult = ReturnType<typeof useCampaignQuery>;
export type CampaignLazyQueryHookResult = ReturnType<typeof useCampaignLazyQuery>;
export type CampaignSuspenseQueryHookResult = ReturnType<typeof useCampaignSuspenseQuery>;
export type CampaignQueryResult = Apollo.QueryResult<CampaignQuery, CampaignQueryVariables>;
export const CampaignClientDocument = gql`
    query campaignClient($hubspot_company_id: bigint!) {
  clients_by_pk(hubspot_company_id: $hubspot_company_id) {
    hubspot_company_id
    name
    username
    hubspot_deals {
      id
      project {
        id
        campaigns(where: {hide_on_client_system: {_eq: false}}) {
          id
          stub
        }
      }
    }
  }
}
    `;

/**
 * __useCampaignClientQuery__
 *
 * To run a query within a React component, call `useCampaignClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignClientQuery({
 *   variables: {
 *      hubspot_company_id: // value for 'hubspot_company_id'
 *   },
 * });
 */
export function useCampaignClientQuery(baseOptions: Apollo.QueryHookOptions<CampaignClientQuery, CampaignClientQueryVariables> & ({ variables: CampaignClientQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignClientQuery, CampaignClientQueryVariables>(CampaignClientDocument, options);
      }
export function useCampaignClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignClientQuery, CampaignClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignClientQuery, CampaignClientQueryVariables>(CampaignClientDocument, options);
        }
export function useCampaignClientSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CampaignClientQuery, CampaignClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CampaignClientQuery, CampaignClientQueryVariables>(CampaignClientDocument, options);
        }
export type CampaignClientQueryHookResult = ReturnType<typeof useCampaignClientQuery>;
export type CampaignClientLazyQueryHookResult = ReturnType<typeof useCampaignClientLazyQuery>;
export type CampaignClientSuspenseQueryHookResult = ReturnType<typeof useCampaignClientSuspenseQuery>;
export type CampaignClientQueryResult = Apollo.QueryResult<CampaignClientQuery, CampaignClientQueryVariables>;