import { type FC, useEffect, useState } from "react";

import styled from "@emotion/styled";
import { Dialog, IconButton } from "@mui/material";
import { Button } from "@relatable/ui/Button";

import { InfoCard } from "components/ui/InfoCard";
import { Textfield } from "components/ui/Textfield";
import type { ClientsOverviewQuery } from "hooks/generated";

import { Add, Close, Remove } from "@mui/icons-material";
import {
  ClientContactsDocument,
  useClientContactsQuery,
  useCreateClientContactsMutation
} from "../generated";
import { ExistingCollaboratorsContainer } from "./ExistingCollaboratorsContainer";

const getDefaultState = () => ({
  id: `new-${new Date().getTime()}`,
  first_name: "",
  last_name: "",
  email: "",
  __typename: "client_contact"
});

export const InviteCollaboratorModal: FC<{
  open: boolean;
  closeModal: () => void;
  project: ClientsOverviewQuery["clients"][number]["hubspot_deals"][number]["project"];
  hubspotCompanyId: string;
}> = ({ open, closeModal, hubspotCompanyId, project }) => {
  const [collaborators, setCollaborators] = useState([getDefaultState()]);
  // formErrors[id][fieldName]
  const [formErrors, setFormErrors] = useState<Record<string, Record<string, boolean | string>>>(
    {}
  );
  const formIsValid = Object.values(formErrors).every(row => Object.values(row).every(r => !r));
  const {
    data: { project_client_contacts = [] } = {}
  } = useClientContactsQuery({
    variables: { projectId: Number(project.id) }
  });
  const [successfulInvite, setSuccessfulInvite] = useState<boolean | null>(null);
  const [createClientContacts, { loading: createClientContactsLoading }] =
    useCreateClientContactsMutation({
      refetchQueries: [ClientContactsDocument],
      onCompleted: () => {
        setSuccessfulInvite(true);
        setCollaborators([getDefaultState()]);
      },
      onError: err => {
        console.log(err);
        setSuccessfulInvite(false);
      }
    });

  useEffect(() => {
    if (open) {
      setSuccessfulInvite(null);
    }
  }, [open]);

  const getTextChangeHandler =
    (id: number | string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      if (name === "email") {
        setFormErrors(prev => ({
          ...prev,
          [id]: {
            email: [...project_client_contacts, ...collaborators].find(
              c => c.email === value && c.id !== id
            )
              ? "Email already exists"
              : false
          }
        }));
      }
      setCollaborators(prev => prev.map(c => (c.id === id ? { ...c, [name]: value } : c)));
    };

  const handleInviteCollaborators = async e => {
    if (!formIsValid) {
      return;
    }
    e.preventDefault();
    createClientContacts({
      variables: {
        objects: collaborators.map(({ __typename, id, ...c }) => ({
          ...c,
          clients_hubspot_company_id: hubspotCompanyId,
          project_id: project.id
        }))
      }
    });
  };

  const addCollaborator = () => setCollaborators(prev => [...prev, getDefaultState()]);
  const removeCollaborator = (id: number | string) => () => {
    setFormErrors(prev => ({
      ...prev,
      [id]: {}
    }));
    setCollaborators(prev => prev.filter(c => c.id !== id));
  };

  return (
    <Root open={open} maxWidth="lg" onClose={closeModal}>
      <StyledInfoCard className="invite-collaborators-modal">
        <StyledCloseIcon fontSize="large" onClick={closeModal} />
        {successfulInvite === null ? (
          <>
            <h2>Invite collaborators</h2>

            <form onSubmit={handleInviteCollaborators}>
              {collaborators.map((c, index) => (
                <div key={c.id}>
                  <NewCollaboratorRow>
                    <Textfield
                      style={{ marginLeft: 0 }}
                      name="first_name"
                      placeholder="First name"
                      value={c.first_name}
                      onChange={getTextChangeHandler(c.id)}
                      required
                    />
                    <Textfield
                      name="last_name"
                      placeholder="Last name"
                      value={c.last_name}
                      onChange={getTextChangeHandler(c.id)}
                      required
                    />
                    <Textfield
                      name="email"
                      placeholder="Business email"
                      value={c.email}
                      onChange={getTextChangeHandler(c.id)}
                      type="email"
                      required
                      errorMessage={
                        formErrors[c.id]?.email ? formErrors[c.id]?.email.toString() : undefined
                      }
                    />
                    <StyledIconButton
                      onClick={addCollaborator}
                      {...(collaborators.length !== index + 1 && {
                        style: { visibility: "hidden" }
                      })}
                    >
                      <Add />
                    </StyledIconButton>
                    <StyledIconButton
                      {...(collaborators.length < 2
                        ? { disabled: true, style: { visibility: "hidden" } }
                        : { onClick: removeCollaborator(c.id) })}
                    >
                      <Remove />
                    </StyledIconButton>
                  </NewCollaboratorRow>
                  {collaborators.length !== index + 1 ? <hr /> : null}
                </div>
              ))}
              <Button
                isLoading={createClientContactsLoading}
                onClick={() => null}
                type="submit"
                variant="outlined"
                disabled={createClientContactsLoading || !formIsValid}
              >
                Invite Collaborators
              </Button>
            </form>
          </>
        ) : (
          <h2>
            {successfulInvite
              ? "Invites successfully sent"
              : "Something went wrong... Please get in touch if the error persists"}
          </h2>
        )}
      </StyledInfoCard>

      <ExistingCollaboratorsContainer project_client_contacts={project_client_contacts} />
    </Root>
  );
};

const Root = styled(Dialog)`
  .invite-collaborators-modal {
    padding: 24px;
    width: 800px;
  }
`;

const StyledInfoCard = styled(InfoCard)`
  && {
    border-radius: 0px;
  }
`;

const StyledCloseIcon = styled(Close)`
  position: absolute;
  top: 24px;
  right: 24px;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;

const NewCollaboratorRow = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0px;

  > .MuiFormControl-root {
    margin: 0px 4px;
    width: 240px;
  }
`;

const StyledIconButton = styled(IconButton)`
  width: 32px;
  height: 32px;
`;
