import type { FC } from "react";

import styled from "@emotion/styled";
import { clientApproved, managerApproved } from "@relatable/helpers/approvalLogs";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { Link, useLocation, useParams } from "react-router-dom";

import { PlatformIcon } from "@relatable/ui/src/icons/PlatformIcon";
import { Container } from "components/Layout";
import { StatusChip } from "components/ui/StatusChip";
import type { ClientsOverviewQuery } from "hooks/generated";
import { getContentState } from "modules/campaign/ContentApproval/common/helpers";

import { Build } from "@mui/icons-material";
import { useMarketsQuery } from "@relatable/gql/generated";
import type { Campaign_User_State_Enum } from "@relatable/gql/generated-base";
import { Loader } from "@relatable/ui/Loader";
import { getCampaignUserState } from "modules/campaign/Creators/utils/creator_transform";
import { useOverviewContentStateQuery } from "./generated";

type Project = ClientsOverviewQuery["clients"][number]["hubspot_deals"][number]["project"];

export const ProjectTile: FC<{ project: Project; withAccordion?: boolean }> = ({
  project,
  withAccordion = false
}) => {
  const marketsQuery = useMarketsQuery();
  const { stub, name, campaigns } = project;

  const { hubspotCompanyId } = useParams<{ hubspotCompanyId?: string }>();
  const {
    loading: contentStateLoading,
    data: { campaigns: campaignState = [] } = {}
  } = useOverviewContentStateQuery({
    variables: {
      where: hubspotCompanyId
        ? {
            hide_on_client_system: { _eq: false },
            project: { hubspot_deals: { hubspot_company_id: { _eq: hubspotCompanyId } } }
          }
        : { hide_on_client_system: { _eq: false } }
    }
  });

  const { pathname } = useLocation();

  const pathnameForCampaignFunctions = pathname.includes(`/p/${stub}`)
    ? pathname
    : `${pathname}/p/${stub}`;

  const renderCreatorListLink = (c: Project["campaigns"][number]) => {
    const { campaign_client_sheet_latest, campaign_users } = c;
    const approvedStates: Campaign_User_State_Enum[] = ["client_approved", "approved"];
    const creatorStates = campaign_users.map(cu =>
      getCampaignUserState({
        state: cu.state as Campaign_User_State_Enum,
        state_log: cu?.state_log
      })
    );
    const approvedCreators = creatorStates.filter(cs => approvedStates.includes(cs.state));
    const minCreators = campaign_client_sheet_latest?.min_creators ?? 1000;
    const state: { background?: string; label: string } = (() => {
      if (!campaign_client_sheet_latest?.state) {
        return {
          background: undefined,
          label: "Not ready yet"
        };
      }
      if (approvedCreators.length >= minCreators) {
        return {
          background: palette.primary.green,
          label: "Approved"
        };
      }

      return {
        background: palette.primary.red,
        label: "Needs Review"
      };
    })();

    return (
      <Link
        className={`step ${state === null ? "disabled" : ""}`}
        {...(state !== null
          ? { to: `${pathnameForCampaignFunctions}/c/${c.stub}/creators` }
          : {
              to: "#",
              component: ({ children, className }) => <div className={className}>{children}</div>
            })}
      >
        <span className="name">Creator List</span>
        <StatusChip label={state.label} backgroundColor={state.background} />
      </Link>
    );
  };

  const renderContentApprovalLink = (c: Project["campaigns"][number]) => {
    if (contentStateLoading || !c) return null;

    const contents = campaignState
      .find(cs => cs.id === c.id)
      ?.contents?.filter(content => managerApproved(getContentState(content).state));

    if (!contents || contents.length === 0) return null;

    const stateLabel = (() => {
      const hasNonReviewedContent = contents.some(contentItem => {
        const contentState = getContentState(contentItem);
        return !contentState.allItemsReviewed;
      });

      if (hasNonReviewedContent) {
        return {
          label: "Needs review",
          color: palette.primary.red
        };
      }

      const allStates = contents.map(contentItem => getContentState(contentItem).state);

      return allStates.every(state => clientApproved(state))
        ? { label: "Preapproved", color: palette.primary.green }
        : { label: "Partially approved", color: palette.primary.gold };
    })();

    return (
      <Link className="step" to={`${pathnameForCampaignFunctions}/c/${c.stub}/approval`}>
        <span className="name">Content Approval</span>

        <StatusChip
          label={stateLabel.label}
          backgroundColor={stateLabel.color}
          color={palette.gray.white}
        />
      </Link>
    );
  };

  return (
    <Root>
      {withAccordion ? (
        <h3 className="title">
          <Link to={pathnameForCampaignFunctions}>{name}</Link>
        </h3>
      ) : null}
      {(project.archived || project.is_ready_for_client_review) && (
        <Button
          style={{ marginBottom: 20 }}
          href={`/overview/p/${project.stub}/review`}
          variant={project.project_client_review?.is_submitted ? "outlined" : "contained"}
          color={project.project_client_review?.is_submitted ? "secondary" : "primary"}
        >
          {project.project_client_review?.is_submitted ? "Re submit review" : "Add review"}
        </Button>
      )}

      <CampaignsContainer>
        {campaigns.map(c => (
          <CampaignContainer key={c.id}>
            <div className="campaign-header">
              <div>
                <h4>{c.title}</h4>
                <span className="markets">
                  {(c.market_ids as number[])
                    .map(id => marketsQuery.data?.market.find(m => m.id === id)?.name)
                    ?.join(", ")}
                </span>
              </div>
              <PlatformIcon platform={c.platform} />
            </div>
            <div className="campaign-body">
              <h4 style={{ display: "flex", gap: 5 }}>
                <Build /> Build campaign
              </h4>
              {!contentStateLoading ? (
                <div className="steps">
                  {renderCreatorListLink(c)}
                  {renderContentApprovalLink(c)}
                </div>
              ) : (
                <Loader />
              )}
            </div>
          </CampaignContainer>
        ))}
      </CampaignsContainer>
    </Root>
  );
};

const Root = styled(Container)`
  margin-bottom: 40px;

  > .title {
    opacity: 0.85;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
      opacity: 1;
    }

    > a {
      color: initial;
    }
  }
`;

const CampaignsContainer = styled.div`
  margin-top: 4px;
  display: grid;
  grid-template-columns: repeat(2, 49%);
  grid-gap: 2%;
`;

const CampaignContainer = styled.div`
  border: 1px solid ${palette.gray[20]};
  box-sizing: border-box;
  border-radius: 4px;

  > .campaign-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    border-bottom: 1px solid ${palette.gray[20]};

    > .markets {
      display: inline-block;
      margin-top: 4px;
      color: ${palette.gray[80]};
    }
  }

  > .campaign-body {
    padding: 12px 24px;

    > .steps {
      margin-top: 8px;

      > .step {
        display: flex;
        align-items: center;
        padding: 4px 12px 4px 24px;
        color: inherit;

        &:not(.disabled) {
          opacity: 0.85;

          &:hover {
            opacity: 1;
          }
        }

        &.disabled {
          opacity: 0.5;
        }

        > .name {
          margin-right: auto;
          color: inherit;
        }
      }
    }
  }
`;
